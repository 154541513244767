import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { EstadoDocente } from './EstadoDocente'
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded'

export const ButtonEstado = ({ codDocente }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
      >
        <ToggleOnRoundedIcon />
      </IconButton>
      <EstadoDocente
        open={open}
        onClose={handleClose}
      />
    </div>
  )
}