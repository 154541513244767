import {
  Box,
  Button, Grid, IconButton
} from '@mui/material'
import ContentMainBox from '../../../components/MainBox'
import CreateSemestre from './components/CreateSemestre'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded'
import ToggleOffRoundedIcon from '@mui/icons-material/ToggleOffRounded'
import UpdateSemestre from './components/UpdateSemestre'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { useGetAllSemestre } from '../../../hooks/useSemestre'
import { useState } from 'react'

const Semestres = () => {
  const { data: semestres } = useGetAllSemestre() || []
  const columns = [
    {
      headerClassName: 'super-app-theme--header',
      field: 'nomSemestre',
      headerName: 'Nombre de Semestre',
      type: 'string',
      width: 200,
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'fecInicio',
      headerName: 'Fecha de inicio',
      width: 180,
      type: 'date',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'fecFIn',
      headerName: 'Fecha final',
      width: 180,
      type: 'date',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'activo',
      type: 'actions',
      headerName: 'Activo',
      cellClassName: 'activo',
      getActions: ({ cod_semestre }) => {
        const semestre = semestres.find(row => row.cod_semestre === cod_semestre)
        return [
          <IconButton
            aria-label="estado de semestre"
            onClick={() => { }}
          >
            {semestre?.estadoSemestre === "C" ? <ToggleOffRoundedIcon /> : <ToggleOnRoundedIcon color='primary' />}
          </IconButton>
        ]
      }
    }
  ]
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mis semestres
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGridStyle
                height={480}
                rows={semestres || []}
                columns={columns}
                getRowId={(row) => row.codSemestre}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

export default Semestres
