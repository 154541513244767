import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'


export const GetAll = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Semestre`)
  return data.semestre
}

export const Create = async body => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.post(`${fullPath}/Semestre`, body)
  return data
}

export const GetById = async id => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Semestre/${id}`)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.put(
    `${fullPath}/Semestre/${id}`,
    body
  )
  return data
}

export const DeleteElement = async id => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.delete(
    `${fullPath}/Semestre/${id}`
  )
  return data
}
