import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React from 'react'
import Input from '../../../../../components/Input';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import SaveIcon from '@mui/icons-material/Save'
import { useForm } from 'react-hook-form';

export const EstadoDocente = (props) => {
    const { onClose, selectedValue, open } = props;
    const { handleSubmit, reset, control, isLoading } = useForm({
        defaultValues: {
            tituloSesion: '',
        }
    })
    const handleClose = () => {
        onClose(selectedValue);
    };
    const onSubmit = () => {}
    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                keepMounted
                maxWidth={'sm'}
                fullWidth={true}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Desactivar Tutor</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Input
                                    control={control}
                                    id='razón'
                                    label='Razón'
                                    placeholder='Ingrese la razón de la desactivación'
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
                            handleClose()

                            reset()
                        }}>
                            CANCELAR
                        </Button>
                        <LoadingButton
                            loading={isLoading}
                            type='submit'
                            loadingPosition='start'
                            startIcon={<SaveIcon />}
                            variant='contained'
                        >
                            GUARDAR
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
