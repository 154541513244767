import { useQuery } from 'react-query'
import {
  GetAll,
  GetDepartamentoResumen
} from '../services/tutor'

const key = 'tutores'

export const useGetAllTutor = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetDepartamentoResumen = (enabled = true) =>
  useQuery('departamentos', GetDepartamentoResumen, {
    enabled
  })

