import React, { useState } from 'react'
import {
  Autocomplete,
  Button, Grid, IconButton, MenuItem, TextField
} from '@mui/material'
import ContentMainBox from '../../../components/MainBox'
import ReduceCapacityRoundedIcon from '@mui/icons-material/ReduceCapacityRounded'
import { ButtonVerAlumnos } from './components/Alumnos/ButtonVerAlumnos'
import { ButtonAsignar } from './components/Asignar/components/ButtonAsignar'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { useGetAllTutor, useGetDepartamentoResumen } from '../../../hooks/useTutor'
import { ButtonEstado } from './components/Docente/ButtonEstado'

export const Tutores = () => {

  const { data: tutores } = useGetAllTutor()
  
  const { data: departamentos } = useGetDepartamentoResumen()
  const [docente, setDocente] = useState(null)
  const [departamento, setDepartamento] = useState('')
  const [filter, setFilter] = useState(false)
  const [dataTutores, setDataTutores] = useState([])
  const comboTutores =
    tutores &&
    tutores.map((unidad) => {
      return unidad.nomDocente
    })
  const comboDepartamentos =
    departamentos ?
      departamentos.map((departamento) => {
        return departamento.nombreDepartamento
      }) : []
  const columns = [
    {
      headerClassName: 'super-app-theme--header',
      field: 'codDocente',
      headerName: 'Codigo',
      type: 'string',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'nomDocente',
      headerName: 'Nombre de Docente',
      width: 350,
      type: 'string',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'nomDepartamentoAca',
      headerName: 'Departamento Academico',
      width: 300,
      type: 'string',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'numTutorados',
      headerName: 'N° Alumnos',
      type: 'string',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'accion',
      type: 'actions',
      width: 180,
      headerName: 'Acción',
      cellClassName: 'accion',
      getActions: ({ row }) => {
        return [
          <IconButton
            onClick={() => { }}
            disabled
          >
            <ReduceCapacityRoundedIcon />
          </IconButton>,
          <ButtonVerAlumnos codDocente={row.codDocente} />,
          <ButtonAsignar codDocente={row.codDocente} />

        ]
      }
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'activo',
      type: 'actions',
      headerName: 'Activo',
      cellClassName: 'activo',
      getActions: ({ row }) => {
        const tutor = tutores.find(tutor => tutor.codDocente === row.codDocente)
        return [
          <ButtonEstado />
        ]
      }
    },
  ]

  const handleChange = (event) => {
    setDepartamento(event.target.value);
  };
  const onFilter = () => {
    let newTutores =
      tutores &&
      tutores.filter((unidad) => {
        if (departamento === '' && docente !== '') {
          if (unidad.nomDocente === docente) {
            return unidad
          }
        }
        if (departamento !== '' && docente === null) {
          if (unidad.nomDepartamentoAca === departamento) {
            return unidad
          }
        }
        if (departamento !== '' && docente !== '') {
          if (unidad.nomDepartamentoAca === departamento && unidad.nomDocente === docente) {
            return unidad
          }
        }
      })
    setDataTutores(newTutores)
    setFilter(true)
    if (departamento === '' && docente === null) {
      setDataTutores(tutores)
    }
  }
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mis tutores
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={5} className='mt-4'>
              <TextField
                fullWidth
                id="deparatamento"
                label="Departamento Academico"
                select
                value={departamento}
                onChange={handleChange}
                size="small">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {comboDepartamentos?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5} className='mt-4'>
              <Autocomplete
                value={docente}
                onChange={(_, newVal) => {
                  setDocente(newVal)
                }}
                size='small'
                options={comboTutores || []}
                renderInput={(params) => <TextField {...params} label="Nombre Docente" />}
              />
            </Grid>
            <Grid item xs={2} className='mt-4'>
              <Button fullWidth variant='contained' onClick={() => onFilter()}>Filtrar</Button>
            </Grid>
            <Grid item xs={12}>
              <DataGridStyle
                height={380}
                rows={filter ? dataTutores || [] : tutores || []}
                columns={columns}
                getRowId={(row) => row.codDocente + row.nomCarrera}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
