import { Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material'
import React from 'react'
import { DataGridStyle } from '../../../../../components/DataGridStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VerAlumnos = (props) => {

    const { onClose, selectedValue, alumnos, open } = props;
    const columns = [
        {
            headerClassName: 'super-app-theme--header',
            field: 'codEstudiante',
            headerName: 'Código estudiante',
            type: 'string',
            width: 180,
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'nomEstudiante',
            headerName: 'Nombre estudiante',
            width: 370,
            type: 'date',
        }
    ]

    const handleClose = () => {
        onClose(selectedValue);
    };
    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>Lista de tutorados</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGridStyle
                                height={400}
                                rows={alumnos}
                                columns={columns}
                                getRowId={(row) => row.codEstudiante}
                                experimentalFeatures={{ newEditingApi: true }}
                                rowsPerPageOptions={[5]}
                                pageSize={5}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default VerAlumnos
