import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  GetAll,
  GetById,
  Create,
  Update,
  DeleteElement
} from '../services/sesion'

const key = 'sesion'

export const useGetAllSesion = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetByIdSesion = (idSesion, enabled = true) =>
  useQuery(key, GetById(idSesion), {
    enabled
  })

export const useCreateSesion = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdateSesion = idSesion => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(idSesion, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useDeleteSesion = idSesion => {
  const queryClient = useQueryClient()

  return useMutation(() => DeleteElement(idSesion), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
