import {
  Box,
  Button, Grid, IconButton
} from '@mui/material'
import ContentMainBox from '../../../components/MainBox'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded'
import ToggleOffRoundedIcon from '@mui/icons-material/ToggleOffRounded'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { useState } from 'react'
import { useGetDepartamentoResumen } from '../../../hooks/useTutor'

export const Departamentos = () => {
  const {data :departamentos} = useGetDepartamentoResumen()
  const columns = [
    {
      headerClassName: 'super-app-theme--header',
      field: 'depAcademico',
      headerName: 'Código',
      type: 'string',
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'nombreDepartamento',
      headerName: 'Nombre de Departamento',
      type: 'string',
      width: 300,
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'nroAlumnos',
      headerName: 'N° de alumnos matriculados',
      type: 'number',
      width: 200,
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'nroTutores',
      headerName: 'N° de docentes',
      type: 'number',
      width: 200,
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'alumnosxTutor',
      headerName: 'N° de alumnos por tutor',
      type: 'number',
      width: 200,
    },
  ]
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Departamentos academicos
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGridStyle
                height={480}
                rows={departamentos || []}
                columns={columns}
                getRowId={(row) => row.depAcademico}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

