import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../../components/layout/dashboard'
import { Departamentos } from './Departamentos'
import Inicio from './inicio'
import { semestresNavConfig } from './layoutSemestres'
import Semestres from './Semestres'
import { Sesiones } from './Sesiones'
import { Tutores } from './Tutores'
import { Asignar } from './Tutores/components/Asignar'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { USER_ROL } from '../../config/user-rol'

const SemestresDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard navConfig={semestresNavConfig} />}>
        <Route element={<ProtectedRoute />}>
          <Route index element={<Inicio />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='semestres' element={<Semestres />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='tutores' element={<Tutores />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='tutores/asignar/:codDocente' element={<Asignar />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='sesiones' element={<Sesiones />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='departamentos' element={<Departamentos />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="*" element={<Navigate to={`/${USER_ROL}`} />} />
        </Route>
      </Route>
    </Routes>

  )
}

export default SemestresDashboard