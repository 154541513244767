import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Alumno`)
  return data.data
}

export const GetAlumnosAsignar = async ({queryKey}) => {
  const { full_path: fullPath } = useUsuario()
  const [_key, codDocente] = queryKey
  const { data } = await axios.get(`${fullPath}/Alumno/${codDocente}`)
  return data.alumnos
}
