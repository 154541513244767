import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/sesiones`)

  return data.sesiones
}

export const Create = async body => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.post(`${fullPath}/sesiones`, body)

  return data
}

export const GetById = async id => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/sesiones/${id}`)

  return data.data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.put(
    `${fullPath}/sesiones/${id}?CodCiclo=${body.codCiclo}&TituloSesion=${body.tituloSesion}&Observacion=${body.observacion}`
  )
  return data
}

export const DeleteElement = async id => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.delete(
    `${fullPath}/sesiones/${id}`
  )
  return data.data
}
