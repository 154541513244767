import { Autocomplete, Button, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import ContentMainBox from '../../../../../components/MainBox'
import { DataGridStyle } from '../../../../../components/DataGridStyle'
import { useParams } from 'react-router-dom'
import { useGetAlumnosAsignar } from '../../../../../hooks/useAlumno'
import { useAsignarAlumnosTutor } from '../../../../../hooks/useTutorAlumno'
import { useAlertMessage } from '../../../../../context/notifications'
import { useGetCarreras, useGetGeneros } from '../../../../../hooks/useTicket'

export const Asignar = (props) => {
    const { mutate, isLoading, refetch } = useAsignarAlumnosTutor()
    const { showError, showSuccess } = useAlertMessage()
    const { codDocente } = useParams()
    const { data: alumnos } = useGetAlumnosAsignar(codDocente) || []
    const { data: generos } = useGetGeneros() || []
    const { data: carreras } = useGetCarreras(codDocente)
    const columns = [
        {
            headerClassName: 'super-app-theme--header',
            field: 'codAlumno',
            headerName: 'Código estudiante',
            type: 'string',
            width: 200,
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'codNombre',
            headerName: 'Nombre Alumno',
            width: 350,
            type: 'string',
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'nomCarrera',
            headerName: 'Carrera',
            width: 300,
            type: 'string',
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'numTutores',
            headerName: 'N° de tutores',
            type: 'number',
        }
    ]
    const values = [
        {
            value: '0',
            label: '',
        },
        {
            value: '1',
            label: 'valor 1',
        },
        {
            value: '2',
            label: 'valor 2',
        },
        {
            value: '3',
            label: 'valor 3',
        },
        {
            value: '4',
            label: 'valor 4',
        },
    ];
    const comboAlumnos =
        alumnos &&
        alumnos.map((unidad) => {
            return unidad.codNombre
        })
    const comboGeneros =
        generos ?
            generos.map((genero) => {
                return { value: genero.descripcion.toUpperCase(), label: genero.descripcion }
            }) : []
    const comboCarreras =
        carreras ?
            carreras.map((carrera) => {
                return { value: carrera.codCarrera, label: carrera.nomCarrera }
            }) : []
    console.log(carreras)
    const [alumnosSeleccionados, setAlumnosSeleccionados] = useState([])
    const asignarAlumnosTutor = () => {
        const data = {
            codDocente: codDocente,
            alumnos: alumnosSeleccionados,
        }
        mutate(data, {
            onError: () => {
                showError('no se pudo asginar los alumnos seleccionados')
            },
            onSuccess: () => {
                refetch
                showSuccess('se asignó los alumnos seleccionados')
            }
        })
    }
    const [genero, setGenero] = useState('')
    const [carrera, setCarrera] = useState('')
    const [alumno, setAlumno] = useState(null)
    const [filter, setFilter] = useState(false)
    const [dataAlumnos, setDataAlumnos] = useState([])
    const onFilter = () => {
        let newAlumnos =
            alumnos &&
            alumnos.filter((unidad) => {
                if (genero !== '') {
                    if (unidad.genero === genero) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        newAlumnos =
            newAlumnos &&
            newAlumnos.filter((unidad) => {
                if (carrera !== '') {
                    if (unidad.codCarrera === carrera) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        newAlumnos =
            newAlumnos &&
            newAlumnos.filter((unidad) => {
                if (alumno !== null) {
                    if (unidad.codNombre === alumno) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        setDataAlumnos(newAlumnos)
        setFilter(true)
        if (genero === '' && alumno === null && carrera === '') {
            setDataAlumnos(alumnos)
        }
        console.log(dataAlumnos)
    }
    const handleChangeGenero = (event) => {
        setGenero(event.target.value);
    };
    const handleChangeCarrera = (event) => {
        setCarrera(event.target.value);
    };
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <div className='flex justify-between'>
                        Asignación tutoría
                    </div>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className='mt-4'>
                            <TextField
                                fullWidth
                                id="sexo"
                                label="Sexo"
                                select
                                value={genero}
                                onChange={handleChangeGenero}
                                size="small">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {comboGeneros.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3} className='mt-4'>
                            <TextField
                                fullWidth
                                id="carrera"
                                label="Carrera"
                                select
                                value={carrera}
                                onChange={handleChangeCarrera}
                                size="small">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {comboCarreras.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3} className='mt-4'>
                            <Autocomplete
                                value={alumno}
                                onChange={(_, newVal) => {
                                    setAlumno(newVal)
                                }}
                                size='small'
                                options={comboAlumnos || []}
                                renderInput={(params) => <TextField {...params} label="Nombre Alumno" />}
                            />
                        </Grid>
                        <Grid item xs={2} className='mt-4'>
                            <Button fullWidth variant='contained' onClick={() => onFilter()}>Filtrar</Button>
                        </Grid>
                        <Grid item className='mt-4' xs={12}>
                            <div style={{ height: 380, width: '100%' }}>
                                <DataGridStyle
                                    height={380}
                                    rows={filter ? dataAlumnos || [] : alumnos || []}
                                    columns={columns}
                                    checkboxSelection
                                    getRowId={(row) => row.codAlumno}
                                    setAlumnosSeleccionados={setAlumnosSeleccionados}
                                    experimentalFeatures={{ newEditingApi: true }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Button fullWidth variant='contained' onClick={() => asignarAlumnosTutor()}>Asignar</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button fullWidth variant='contained' color='secondary'>automatico</Button>
                        </Grid>
                    </Grid>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}
