import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import './App.css';
import { ProtectedRoute } from './components/ProtectedRoute';
import SemestresDashboard from './pages/Administrador';
import Login from './pages/Login';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/login" element={<Login />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/administrador/*" element={<SemestresDashboard />} />
        </Route>

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
