import { Navigate, Outlet } from 'react-router-dom';
import { isLoggedIn } from '../services/login';


export const ProtectedRoute = ({ children, redirectTo = "/login" }) => {
  const login = isLoggedIn();

  if (!login) {
    return <Navigate to={redirectTo}></Navigate>
  }

  return <Outlet />
}
