import SesionForm from './SesionForm'
import { useUpdateSesion } from '../../../../hooks/useSesion'

const UpdateSesion = ({ data, id_sesion }) => {
  const { mutate } = useUpdateSesion(id_sesion)
  return (
    <>
      <SesionForm edit={true} data={data} mutate={mutate} />
    </>
  )
}

export default UpdateSesion
