import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  GetAll,
  GetAlumnosAsignar
} from '../services/alumno'

const key = 'alumno'

export const useGetAllAlumno = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetAlumnosAsignar = (codDocente, enabled = true) =>
  useQuery([key, codDocente], GetAlumnosAsignar, {
    enabled
  })
