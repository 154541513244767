import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetCiclos = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Ticket/ciclo`)
  return data.ciclos
}

export const GetGeneros = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Ticket/genero`)
  return data.generos
}

export const GetCarreras = async ({queryKey}) => {
  const { full_path: fullPath } = useUsuario()
  const [_key, codDocente] = queryKey
  const { data } = await axios.get(`${fullPath}/Ticket/carreras-departamento/${codDocente}`)
  return data.carreras
}
