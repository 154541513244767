
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded'
import { IconButton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const ButtonAsignar = ({ codDocente }) => {
    const handleClickOpen = () => {
    }
    return (
        <div>
            <Link
                key="notunique"
                to={`asignar/${codDocente}`}
            >
                <IconButton
                    onClick={handleClickOpen}
                >
                    <PersonAddRoundedIcon />
                </IconButton>
            </Link>
        </div>
    )
}
