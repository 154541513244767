import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import VerAlumnos from './VerAlumnos'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import { useGetAlumnosDeTutor } from '../../../../../hooks/useTutorAlumno';

export const ButtonVerAlumnos = ({ codDocente }) => {
  const [open, setOpen] = React.useState(false);
  const mutation = useGetAlumnosDeTutor()
  const handleClickOpen = () => {
    mutation.mutate(codDocente)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
      >
        <RemoveRedEyeRoundedIcon />
      </IconButton>
      <VerAlumnos
        open={open}
        onClose={handleClose}
        alumnos={mutation.data || []}
      />
    </div>
  )
}
