import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useAlertMessage } from '../../../../context/notifications'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Input from '../../../../components/Input'
import ButtonAdd from '../../../../components/ButtonAdd'

const SemestreSchema = yup.object({
  nombre: yup
    .string()
    .required('* Campo requerido')
})

const FormSemestre = ({ edit, data, mutate, isLoading }) => {
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')
  const { showError, showSuccess } = useAlertMessage()
  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SemestreSchema),
    defaultValues: {
      semestre: '',
      fecha_inicio: new Date().toJSON().slice(0, 10),
      fecha_fin: new Date().toJSON().slice(0, 10),
      activo: ''
    }
  })

  const handleOpen = () => {
    if (edit) {
      reset({
        semestre: data.semestre,
        fecha_inicio: data.fecha_inicio,
        fecha_fin: data.fecha_fin,
        activo: data.activo,
      })
    }

    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onSubmit = data => {
    const formData = {
      nombre: data.nombre
    }
    const msg = edit ? 'No se pudo editar Semestre' : 'No se pudo crear Semestre'
    const msg2 = edit
      ? 'Semestre editado correctamente'
      : 'Semestre creado correctamente'

    mutate(formData, {
      onError: () => {
        handleClose()
        showError(msg)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }

  return (
    <>
      {edit ? (
        <Tooltip title='Editar Semestre'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <ButtonAdd openModal={handleOpen} />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR SEMESTRE' : 'AÑADIR SEMESTRE'}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información básica de la Semestre
            </DialogContentText>
            <Grid container spacing={3} columns={2} alignContent='center'>
              <Grid item xs={2}>
                <Input
                  control={control}
                  id='semestre'
                  label='Nombre de Semestre'
                  placeholder='Ingrese Nombre de Semestre'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.semestre?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Input
                  control={control}
                  id='fecha_inicio'
                  label='Fecha Inicio'
                  type='date'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.fecha_inicio?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Input
                  control={control}
                  id='fecha_fin'
                  label='Fecha Final'
                  type='date'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.fecha_fin?.message}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()

              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default FormSemestre
