import { useMutation, useQueryClient } from 'react-query'
import { GetAlumnosDeTutor, AsignarAlumnosTutor } from '../services/tutorAlumno'

const key = 'tutorAlumno'

export const useGetAlumnosDeTutor = () => {
  const queryClient = useQueryClient()

  return useMutation(GetAlumnosDeTutor, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}


export const useAsignarAlumnosTutor = () => {
  const queryClient = useQueryClient()

  return useMutation(body => AsignarAlumnosTutor(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
