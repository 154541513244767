import { useQuery } from 'react-query'
import { GetCiclos, GetGeneros, GetCarreras } from '../services/ticket'

const key = 'ticket'

export const useGetCiclos = (enabled = true) =>
  useQuery('ciclos', GetCiclos, {
    enabled
  })
export const useGetGeneros = (enabled = true) =>
  useQuery('generos', GetGeneros, {
    enabled
  })
export const useGetCarreras = ( codDocente ,enabled = true) =>
  useQuery(['carreras', codDocente], GetCarreras, {
    enabled
  })