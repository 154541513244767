import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Tutor`)
  return data.tutores

}
export const GetDepartamentoResumen = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Tutor/departamento-resumen`)
  return data.depAcademicos
}