import FormSesion from './SesionForm'
import { useCreateSesion } from '../../../../hooks/useSesion'

const CreateSesion = () => {
  const { mutate, isLoading } = useCreateSesion()

  return (
    <>
      <FormSesion edit={false} mutate={mutate} data={''} isLoading={isLoading} />
    </>
  )
}

export default CreateSesion
