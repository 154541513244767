import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  GetAll,
  GetById,
  Create,
  Update,
  DeleteElement
} from '../services/semestre'

const key = 'semestre'

export const useGetAllSemestre = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetByIdSemestre = (idSemestre, enabled = true) =>
  useQuery(key, GetById(idSemestre), {
    enabled
  })

export const useCreateSemestre = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdateSemestre = idSemestre => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(idSemestre, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useDeleteSemestre = idSemestre => {
  const queryClient = useQueryClient()

  return useMutation(() => DeleteElement(idSemestre), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
