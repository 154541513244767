import { Grid } from '@mui/material'
import React from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import ContentMainBox from '../../../components/MainBox'
import CreateSesion from './components/CreateSesion'
import UpdateSesion from './components/UpdateSesion'
import { useGetAllSesion } from '../../../hooks/useSesion'

export const Sesiones = () => {
    const { data: sesiones } = useGetAllSesion()
    const columns = [
        {
            headerClassName: 'super-app-theme--header',
            field: 'codSesion',
            headerName: 'Código',
            type: 'string'
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'tituloSesion',
            headerName: 'Título',
            width: 250,
            type: 'string'
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'observacion',
            headerName: 'Observacion',
            width: 250,
            type: 'string'
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'codCiclo',
            headerName: 'Ciclo',
            type: 'string'
        },
        {
            headerClassName: 'super-app-theme--header',
            field: 'acciones',
            type: 'actions',
            headerName: 'Acciones',
            cellClassName: 'acciones',
            getActions: ({ row }) => {
                const data = sesiones.find(sesion => sesion.codSesion === row.codSesion)
                return [
                    <UpdateSesion data={data} id_sesion={row.codSesion} />
                ]
            }
        }
    ]
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <div className='flex justify-between'>
                        Sesiones
                    </div>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <CreateSesion />
                        </Grid>
                        <Grid item xs={12}>
                            <DataGridStyle
                                height={380}
                                rows={sesiones || []}
                                columns={columns}
                                getRowId={(row) => row.codSesion}
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Grid>
                    </Grid>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}
