import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAlumnosDeTutor = async (codDocente) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.post(`${fullPath}/TutorAlumno?Codigo=${codDocente}`)
  return data.tutoAlumno
}
export const AsignarAlumnosTutor = async (body) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.post(`${fullPath}/TutorAlumno/asignar?CodTutor=${body.codDocente}`,body.alumnos)
  return data
}
