import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useAlertMessage } from '../../../../context/notifications'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Input from '../../../../components/Input'
import ButtonAdd from '../../../../components/ButtonAdd'
import { useGetCiclos } from '../../../../hooks/useTicket'

const SesionSchema = yup.object({
  tituloSesion: yup
    .string()
    .required('* Campo requerido'),
  codCiclo: yup
    .string()
    .required('* Campo requerido'),
  observacion: yup
    .string()
    .required('* Campo requerido')
})

const FormSesion = ({ edit, data, mutate, isLoading }) => {
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const { showError, showSuccess } = useAlertMessage()
  const { data: ciclos } = useGetCiclos()

  const handleOpen = () => {
    if (edit) {
      console.log(data)
      reset({
        tituloSesion: data.tituloSesion,
        codCiclo: data.codCiclo,
        observacion: data.observacion
      })
    }
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onSubmit = data => {
    const formData = {
      tituloSesion: data.tituloSesion,
      codCiclo: data.codCiclo,
      observacion: data.observacion
    }
    const msg = edit ? 'No se pudo editar Sesion' : 'No se pudo crear Sesion'
    const msg2 = edit
      ? 'Sesion editado correctamente'
      : 'Sesion creado correctamente'

    mutate(formData, {
      onError: () => {
        handleClose()
        showError(msg)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }

  const comboCiclos =
    ciclos &&
    ciclos.map((ciclo) => {
      return { value: ciclo.codCiclo, denominacion: ciclo.descripcion}
    })

  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SesionSchema),
    defaultValues: {
      tituloSesion: '',
      codCiclo: '',
      observacion: ''
    }
  })

  return (
    <>
      {edit ? (
        <Tooltip title='Editar Sesion'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <ButtonAdd openModal={handleOpen} />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR SESION' : 'AÑADIR SESION'}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información básica de la Sesion
            </DialogContentText>
            <Grid container spacing={3} alignContent='center'>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='tituloSesion'
                  label='Título'
                  placeholder='Ingrese título'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.tituloSesion?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='codCiclo'
                  label='Ciclo'
                  type='select'
                  data={comboCiclos}
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.ciclo?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='observacion'
                  label='Observación'
                  placeholder='Ingrese observación'
                  multiline
                  rows={3}
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.observacion?.message}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()

              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default FormSesion
