import HomeIcon from '@mui/icons-material/Home'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import HailRoundedIcon from '@mui/icons-material/HailRounded'
import EventRoundedIcon from '@mui/icons-material/EventRounded'
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded'

export function semestresNavConfig() {
  return {
    links: [
      { path: '.', name: 'Inicio', icon: HomeIcon },
      {
        path: 'semestres',
        name: 'Mis semestres',
        icon: CalendarTodayRoundedIcon
      },
      {
        path: 'tutores',
        name: 'Mis tutores',
        icon: HailRoundedIcon
      },
      {
        path: 'sesiones',
        name: 'Sesiones',
        icon: EventRoundedIcon
      },
      {
        path: 'departamentos',
        name: 'Departamentos',
        icon: SchoolRoundedIcon
      }
    ]
  }
}
